import axiosInstance from '../../axios';

const state = {
	odds: {},
	updatedOdds: {},
	oddsLoaded: false,
	updatingOdds: false,
};

const mutations = {
	setOdds(state, odds) {
		state.odds = odds;
	},
	setUpdatedOdds(state, odds) {
		state.updatedOdds = odds;
	},
	setOddsLoaded(state, loaded) {
		state.oddsLoaded = loaded;
	},
	setUpdatingOdds(state, updating) {
		state.updatingOdds = updating;
	}
};

const actions = {
	async fetchOdds({ commit, state }) {
		if (state.oddsLoaded) return;
		try {
			const response = await axiosInstance.get('/sport/football/odds/main?companyId=23');

			commit('setOdds', response.data.data);
			commit('setOddsLoaded', true);
		} catch (error) {
			console.error('Error fetching odds:', error);
		}
	},
	async updateOdds({ commit }) {
		if (state.updatingOdds) return;
		commit('setUpdatingOdds', true);
		try {
			const response = await axiosInstance.get('/sport/football/odds/main/changes?companyId=23');

			commit('setUpdatedOdds', response.data.data);
		} catch (error) {
			console.error('Error updating odds:', error);
		} finally {
			setTimeout(() => {
				commit('setUpdatingOdds', false);
			}, 30000);
		}
	},
};

const getters = {
	getOdds(state) {
		return state.odds;
	},
	getUpdatedOdds(state) {
		return state.updatedOdds;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
