<template>
	<div class="score-data">
		<div class="row align-items-center filters">
			<div class="col-md-6">
				<div class="d-flex flex-row justify-content-center justify-content-md-start gap-1 filter-type">
					<a href="#" class="text-center type all active" id="all">{{ $tr('all') }}</a>
					<a href="#" class="text-center type hot-games d-none" id="hot-games">{{ $tr('hot_games') }}</a>
					<a href="#" class="text-center type leagues d-none" id="leagues">{{ $tr('leagues') }}</a>
					<a href="#" class="text-center type odds d-none" id="odds">{{ $tr('odds') }}</a>
				</div>
			</div>
			<div class="col-md-6">
				<div class="d-flex justify-content-end filter-date d-none">
					<div class="d-flex flex-row align-items-center justify-content-between gap-2">
						<i class="fas fa-chevron-left tertiary-color fw-bold cursor-pointer dp__pointer" id="prev" @click="updateSelectedDate('prev')"></i>
						<div class="d-flex flex-row align-items-center gap-2">
							<i class="fas fa-calendar-days solid-color" id="calendar"></i>
							<!-- <span class="primary-color filter-text">13/11 HAI</span> -->
							<DatePicker v-model="selectedDate" :value="selectedDate" @update:model-value="handleDateSelected" />
						</div>
						<i class="fas fa-chevron-right tertiary-color fw-bold cursor-pointer dp__pointer" id="next" @click="updateSelectedDate('next')"></i>
					</div>
				</div>
			</div>
		</div>

		<div class="table-data">
			<div v-if="displayedData && displayedData.length > 0">
				<template v-for="(league, leagueId) in displayedData" :key="leagueId">
					<table class="table mt-3">
						<thead>
							<tr class="header align-middle">
								<th width="2%"><i class="fa-regular fa-star head"></i></th>
								<th width="60%" colspan="3">{{ league.leagueName }}</th>
								<th class="text-center">{{ $tr('handicap') }}</th>
								<th class="text-center">{{ $tr('over_under') }}</th>
								<th class="text-center">{{ $tr('one_two') }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(match, matchIndex) in league.matches" :key="matchIndex">
								<td class="align-content-center"><i class="fa-regular fa-star data ps-2"></i></td>
								<td width="10%" class="align-content-center">
									<div>
										<p class="d-flex flex-column m-0" v-html="formatTime(match.matchTime)"></p>
										<template v-if="(match.status === 2)">
											<span class="text-info">
												HT
											</span>
										</template>
										<span v-if="(match.status === 1 || match.status === 3)" class="text-danger">
											{{ formatMatchTime(match.halfStartTime, match.status) }}
										</span>
									</div>
								</td>
								<td width="25%">
									<div class="d-flex flex-column gap-1 teams">
										<div class="d-flex">
											<span v-if="match.homeLogo" class="me-2"><img :src="storage_api_url + match.homeLogo" :alt="match.homeName" style="width: 24px;"></span>
											{{ match.homeName }}
										</div>
										<div class="d-flex">
											<span v-if="match.awayLogo" class="me-2"><img :src="storage_api_url + match.awayLogo" :alt="match.awayName" style="width: 24px;"></span>
											{{ match.awayName }}
										</div>
										<div class="d-flex">
											{{ $tr('draw') }}
										</div>
									</div>
								</td>
								<td width="25%">
									<div class="d-flex flex-column gap-1 score">
										<div class="d-flex justify-content-center align-items-center">
											<p class="bold text-white m-0">{{ match.homeScore ? match.homeScore : '-' }}</p>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<p class="bold text-white m-0">{{ match.awayScore ? match.awayScore : '-' }}</p>
										</div>
									</div>
								</td>
								<td>
									<div class="colored d-flex flex-column gap-1">
										<div class="d-flex flex-row gap-2 justify-content-end align-items-center mx-auto">
											<template v-if="match.odds.handicap && match.odds.handicap.length > 0">
												<template v-for="(handicap, handicapIndex) in match.odds.handicap" :key="handicapIndex">
													<template v-if="isLive(match.status)">
														<span class="me-1 live" >{{ handicap.split(',')[2] }}</span>
														<span class="bold live" >{{ handicap.split(',')[3] }}</span>
														<i class="ms-1" :class="getClass(handicap, previousOdds?.handicap?.[handicapIndex], 3)"></i>
													</template>
													<template v-else>
														<span class="me-1 pre-game" >{{ handicap.split(',')[2] }}</span>
														<span class="bold pre-game" >{{ handicap.split(',')[3] }}</span>
														<i class="ms-1" :class="getClass(handicap, previousOdds?.handicap?.[handicapIndex], 3)"></i>
													</template>
												</template>
											</template>
											<template v-else>
												<span class="me-2">-</span>
											</template>
										</div>
										<div class="d-flex flex-row gap-2 justify-content-end align-items-center mx-auto">
											<template v-if="match.odds.handicap && match.odds.handicap.length > 0">
												<template v-for="(handicap, handicapIndex) in match.odds.handicap" :key="handicapIndex">
													<template v-if="isLive(match.status)">
														<span class="live" >{{ handicap.split(',')[4] }}</span>
														<i class="ms-1" :class="getClass(handicap, previousOdds?.handicap?.[handicapIndex], 4)"></i>
													</template>
													<template v-else>
														<span class="pre-game" >{{ handicap.split(',')[4] }}</span>
														<i class="ms-1" :class="getClass(handicap, previousOdds?.handicap?.[handicapIndex], 4)"></i>
													</template>
												</template>
											</template>
											<template v-else>
												<span class="me-2">-</span>
											</template>
										</div>
									</div>
								</td>
								<td>
									<div class="colored d-flex flex-column gap-1">
										<div class="d-flex flex-row gap-2 justify-content-end align-items-center mx-auto">
											<template v-if="match.odds.overUnder && match.odds.overUnder.length > 0">
												<template v-for="(overUnder, overUnderIndex) in match.odds.overUnder" :key="overUnderIndex">
													<template v-if="isLive(match.status)">
														<span class="me-1 live" >{{ overUnder.split(',')[2] }}</span>
														<span class="bold live" >{{ overUnder.split(',')[3] }}</span>
														<i class="ms-1" :class="getClass(overUnder, previousOdds?.overUnder?.[overUnderIndex], 3)"></i>
													</template>
													<template v-else>
														<span class="me-1 pre-game" >{{ overUnder.split(',')[2] }}</span>
														<span class="bold pre-game" >{{ overUnder.split(',')[3] }}</span>
														<i class="ms-1" :class="getClass(overUnder, previousOdds?.overUnder?.[overUnderIndex], 3)"></i>
													</template>
												</template>
											</template>
											<template v-else>
												<span class="me-2">-</span>
											</template>
										</div>
										<div class="d-flex flex-row gap-2 justify-content-end align-items-center mx-auto">
											<template v-if="match.odds.overUnder && match.odds.overUnder.length > 0">
												<template v-for="(overUnder, overUnderIndex) in match.odds.overUnder" :key="overUnderIndex">
													<template v-if="isLive(match.status)">
														<span class="live" >{{ overUnder.split(',')[4] }}</span>
														<i class="ms-1" :class="getClass(overUnder, previousOdds?.overUnder?.[overUnderIndex], 4)"></i>
													</template>
													<template v-else>
														<span class="pre-game" >{{ overUnder.split(',')[4] }}</span>
														<i class="ms-1" :class="getClass(overUnder, previousOdds?.overUnder?.[overUnderIndex], 4)"></i>
													</template>
												</template>
											</template>
											<template v-else>
												<span class="me-2">-</span>
											</template>
										</div>
									</div>
								</td>
								<td>
									<div class="colored d-flex flex-column gap-1">
										<div class="d-flex flex-row gap-2 justify-content-end align-items-center mx-auto small">
											<template v-if="match.odds.europeOdds && match.odds.europeOdds.length > 0">
												<template v-for="(europeOdd, europeOddIndex) in match.odds.europeOdds" :key="europeOddIndex">
													<div class="d-flex justify-content-center align-items-center">
														<template v-if="isLive(match.status)">
															<span class="me-1 live" >{{ europeOdd.split(',')[2] }}</span>
															<i :class="getClass(europeOdd, previousOdds?.europeOdds?.[europeOddIndex], 2)"></i>
														</template>
														<template v-else>
															<span class="me-1 pre-game" >{{ europeOdd.split(',')[2] }}</span>
															<i :class="getClass(europeOdd, previousOdds?.europeOdds?.[europeOddIndex], 2)"></i>
														</template>
													</div>
												</template>
											</template>
											<template v-else>
												<span>-</span>
											</template>
										</div>
										<div class="d-flex flex-row gap-2 justify-content-end align-items-center mx-auto small">
											<template v-if="match.odds.europeOdds && match.odds.europeOdds.length > 0">
												<template v-for="(europeOdd, europeOddIndex) in match.odds.europeOdds" :key="europeOddIndex">
													<div class="d-flex justify-content-center align-items-center">
														<template v-if="isLive(match.status)">
															<span class="me-1 live" >{{ europeOdd.split(',')[4] }}</span>
															<i :class="getClass(europeOdd, previousOdds?.europeOdds?.[europeOddIndex], 4)"></i>
														</template>
														<template v-else>
															<span class="me-1 pre-game" >{{ europeOdd.split(',')[4] }}</span>
															<i :class="getClass(europeOdd, previousOdds?.europeOdds?.[europeOddIndex], 4)"></i>
														</template>
													</div>
												</template>
											</template>
											<template v-else>
												<span>-</span>
											</template>
										</div>
										<div class="d-flex flex-row gap-2 justify-content-end align-items-center mx-auto small">
											<template v-if="match.odds.europeOdds && match.odds.europeOdds.length > 0">
												<template v-for="(europeOdd, europeOddIndex) in match.odds.europeOdds" :key="europeOddIndex">
													<div class="d-flex justify-content-center align-items-center">
														<template v-if="isLive(match.status)">
															<span class="me-1 live">{{ europeOdd.split(',')[3] }}</span>
															<i :class="getClass(europeOdd, previousOdds?.europeOdds?.[europeOddIndex], 3)"></i>
														</template>
														<template v-else>
															<span class="me-1 pre-game" >{{ europeOdd.split(',')[3] }}</span>
															<i :class="getClass(europeOdd, previousOdds?.europeOdds?.[europeOddIndex], 3)"></i>
														</template>
													</div>
												</template>
											</template>
											<template v-else>
												<span>-</span>
											</template>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</template>
			</div>
			<div v-else>
				<table class="table mt-3">
					<thead>
						<tr class="header align-middle">
							<th width="2%"><i class="fa-regular fa-star head"></i></th>
							<th width="60%" colspan="3"></th>
							<th class="text-center">{{ $tr('handicap') }}</th>
							<th class="text-center">{{ $tr('over_under') }}</th>
							<th class="text-center">{{ $tr('one_two') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td colspan="7" class="text-center">--No Available Data--</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<a href="#" v-if="!showAll" @click.prevent="showMore" class="load-more text-center d-block">{{ $tr('load_more') }} <span><i class="fas fa-chevron-down ms-1"></i></span></a>
	</div>
</template>
  
<script>
	import DatePicker from '../components/DatePicker.vue';
	import { mapState, mapActions } from 'vuex';

	export default {
		name: "OddsView",
		components: {
			DatePicker
		},

		data() {
			return {
				showAll: false,
				showLimit: 5,
				groupedMatches: {},
				previousOdds: {},
				selectedDate: new Date(),
				filteredMatches: [],
				storage_api_url: process.env.VUE_APP_ISPORTS_TEAM_STORAGE_URL
			};
		},
		methods: {
			...mapActions({
				fetchMatches: 'livescores/fetchMatches',
				updateMatches: 'livescores/updateMatches',
				fetchOdds: 'odds/fetchOdds',
				updateOdds: 'odds/updateOdds',
				fetchTeams: 'teams/fetchTeams',
			}),
			handleDateSelected(date) {
				this.selectedDate = date;
				this.filterAndMergeMatches();
			},
			updateSelectedDate(direction) {
				const newDate = new Date(this.selectedDate);

				if(direction == 'next') {
					newDate.setDate(newDate.getDate() + 1);
				} else if (direction == 'prev') {
					newDate.setDate(newDate.getDate() - 1);
				}

				this.selectedDate = newDate;
				this.filterAndMergeMatches();
			},
			async fetchData() {
				await this.fetchMatches();
				await this.fetchOdds();

				this.filterAndMergeMatches();


				await this.fetchTeams();

				this.mergeData(this.teams);
			},
			async updateData() {
				await this.updateOdds();
				await this.updateMatches();

				// Update only the fields that have changed in matches
				this.updatedMatches.forEach(updatedMatch => {
					const matchIndex = this.matches.findIndex(match => match.matchId === updatedMatch.matchId);
					if (matchIndex !== -1) {
						this.matches[matchIndex] = { ...this.matches[matchIndex], ...updatedMatch };
					}
					// else {
					// 	this.matches.push(updatedMatch);
					// }
				});

				// Update only the fields that have changed in odds
				for (const [key, newOdds] of Object.entries(this.updatedOdds)) {
					if (this.odds[key]) {
						const existingOddsMap = this.odds[key].reduce((acc, odd) => {
							const [matchId] = odd.split(',');
							acc[matchId] = odd;
							return acc;
						}, {});

						newOdds.forEach(odd => {
							const [matchId] = odd.split(',');
							existingOddsMap[matchId] = odd;
						});

						this.odds[key] = Object.values(existingOddsMap);
					} else {
						this.odds[key] = newOdds;
					}
				}

				this.filterAndMergeMatches();
			},
			mergeData(teamLogos) {
				const newGroupedMatches = this.filteredMatches.reduce((acc, match) => {
					const matchOdds = this.findMatchOdds(match.matchId);
					const leagueId = match.leagueId;
					
					if (!acc[leagueId]) {
						acc[leagueId] = {
							leagueName: match.leagueName,
							location: match.location,
							matches: []
						};
					}

					acc[leagueId].matches.push({
						...match,
						homeLogo: teamLogos[match.homeId] || '',
						awayLogo: teamLogos[match.awayId] || '',
						odds: matchOdds,
					});

					return acc;
				}, {});

				this.previousOdds = JSON.parse(JSON.stringify(this.odds));
				this.groupedMatches = newGroupedMatches;
			},
			findMatchOdds(matchId) {
				const odds = {};
				for (const [key, value] of Object.entries(this.odds)) {
					odds[key] = value.filter(odd => odd.split(',')[0] === matchId);
				}
				return odds;
			},
			filterAndMergeMatches() {
				const startDate = new Date(this.selectedDate);
				const endDate = new Date(this.selectedDate);
				endDate.setDate(endDate.getDate() + 7);

				const today = new Date();
				const todayDateString = today.toISOString().split('T')[0];

				this.filteredMatches = this.matches.filter(match => {
					const matchDate = new Date(match.matchTime * 1000);
					const matchDateString = matchDate.toISOString().split('T')[0];
					return (matchDate >= startDate && matchDate <= endDate) || matchDateString === todayDateString;
				});

				this.mergeData(this.teams);
			},
			getClass(current, previous, oddsIndex) {
				const currentValue = parseFloat(current.split(',')[oddsIndex]);
				const previousValue = previous ? parseFloat(previous.split(',')[oddsIndex]) : currentValue;
				if (currentValue > previousValue) {
					return 'fas fa-caret-up text-success';
				} else if (currentValue < previousValue) {
					return 'fas fa-caret-down text-danger';
				} else {
					return 'fas fa-caret-up text-success';
				}
			},
			showMore() {
				this.showLimit += 5;
				
				if (this.showLimit >= Object.values(this.groupedMatches).length) {
					this.showAll = true;
				}
			},
			// formatTime(timestamp) {
			// 	let date = new Date(timestamp * 1000);
			// 	let hours = date.getHours().toString().padStart(2, '0');
			// 	let minutes = date.getMinutes().toString().padStart(2, '0');
			// 	return hours + 'H' + minutes;
			// },
			// formatTime(timestamp) {
			// 	let currentDate = new Date();
			// 	let date = new Date(timestamp * 1000);

			// 	if (date > currentDate) {
			// 		let timeDiffHours = Math.floor((date - currentDate) / (1000 * 60 * 60));

			// 		currentDate.setHours(currentDate.getHours() + timeDiffHours);
			// 	}

			// 	let hours = currentDate.getHours().toString().padStart(2, '0');
			// 	let minutes = currentDate.getMinutes().toString().padStart(2, '0');

			// 	return hours + ':' + minutes;
			// },
			formatTime(timestamp) {
				let currentDate = new Date();  // Get current date
				let date = new Date(timestamp * 1000); // Convert seconds to milliseconds for the given timestamp

				let hours = date.getHours().toString().padStart(2, '0'); // Get hours and format
				let minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes and format
				let formattedTime = hours + ':' + minutes; // Combine hours and minutes with ':' separator

				let currentDay = currentDate.getDate();
				let currentMonth = currentDate.getMonth();
				let currentYear = currentDate.getFullYear();

				let dateDay = date.getDate();
				let dateMonth = date.getMonth();
				let dateYear = date.getFullYear();

				let timeLabel = '';

				if (dateYear === currentYear && dateMonth === currentMonth && dateDay === currentDay) {
					// It's the same day
					timeLabel = 'Today';
					// Check if the time is past the current time today
					if (date.getTime() < currentDate.getTime()) {
						// If the time is in the future, remove "Today" label
						timeLabel = '';
					}
				} else if (dateYear === currentYear && dateMonth === currentMonth && dateDay === currentDay + 1) {
					// It's tomorrow
					timeLabel = 'Tomorrow';
				} else {
					// Any other day
					timeLabel = date.toLocaleString('default', { month: 'long', day: 'numeric' });
				}

				return `${formattedTime}<span>${timeLabel}</span>`;
			},
			formatMatchTime(halfStartTime, status) {
				let elapsedTime = Math.floor(Date.now() / 1000) - halfStartTime;

				// Calculate game time in seconds
				// let gameTime = 0;
				// if (status == 1) {
				// 	gameTime = Math.min(90 * 60, elapsedTime); // Max 90 minutes (5400 seconds)
				// } else if (status == 3) {
				// 	gameTime = Math.min(90 * 60 + 45 * 60, elapsedTime); // Max 90 minutes (5400 seconds)
				// 	if (elapsedTime > 45 * 60) {
				// 		gameTime += 45 * 60; // Add additional 45 minutes for second half
				// 		console.log(gameTime);
				// 	}
				// }

				let minutes = Math.floor(elapsedTime / 60);
				const seconds = elapsedTime % 60;

				if(status === 3) {
					minutes += 45;
				}
				const formattedTime = minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');

				return formattedTime;
			},
			formatUnixTime(unixTime) {
				const date = new Date(unixTime * 1000);
				const dd = String(date.getDate()).padStart(2, '0');
				const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
				const yy = String(date.getFullYear()).slice(-2);
				const hh = String(date.getHours()).padStart(2, '0');
				const min = String(date.getMinutes()).padStart(2, '0');
				return `${dd}-${mm}-${yy} ${hh}:${min}`;
			},
			isLive(status) {
				switch (status) {
					case 1:
					case 2:
					case 3:
					case 4:
					case 5:
						return true;
					default:
						return false;
				}
			}
		},
		created() {
			this.fetchData();
			setInterval(this.updateData, 30000); // 30 seconds
		},
		computed: {
			...mapState({
				matches: state => state.livescores.matches,
				updatedMatches: state => state.livescores.updatedMatches,
				odds: state => state.odds.odds,
				updatedOdds: state => state.odds.updatedOdds,
				teams: state => state.teams.teams,
			}),
			displayedData() {
				const matchesArray = Object.values(this.groupedMatches);

				// show first
				matchesArray.sort((a, b) => {
					const keyA = Object.keys(this.groupedMatches).find(key => this.groupedMatches[key] === a);
					const keyB = Object.keys(this.groupedMatches).find(key => this.groupedMatches[key] === b);
					
					// EURO Cup
					if (keyA === '1763') return -1;
					if (keyB === '1763') return 1;

					// Copa America
					if (keyA === '14228') return -1;
					if (keyB === '14228') return 1;
					return 0;
				});

				return this.showAll ? matchesArray : matchesArray.slice(0, this.showLimit);
			},
			tableChunks() {
				const chunkSize = 5;
				const chunks = [];
				for (let i = 0; i < this.displayedData.length; i += chunkSize) {
					chunks.push(this.displayedData.slice(i, i + chunkSize));
				}
				return chunks;
			}
		}
	}
</script>

<style scoped>
	.filter-type {
		gap: 4px;
	}
	.type {
		padding: 4px 8px;
		border-radius: 8px;
		background-color: #EEEEEE;
		border: 1px solid #EEEEEE;
		font-size: 14px;
		color: #8A8A8A;
		font-weight: 600;
		min-width: 62px;
	}
	.type:hover,
	.type:focus,
	.type.active {
		border: 1px solid #044CBF;
		background-color: #FFFFFF;
		color: #044CBF;
	}
	.filter-text {
		font-size: 14px;
		font-weight: 600;
	}

	.table thead th:first-child, .header th:first-child {
		border-top-left-radius: 10px;
	}
	.table thead th:last-child, .header th:last-child {
		border-top-right-radius: 10px;
	}
	.table thead th, .header th {
		background-color: #044CBF;
		color: #FFFFFF;
		border-bottom: 3px solid #FF644A;
		padding: 8px 16px;
		font-size: 13px;
		font-weight: 700;
	}
	.table td {
		color: #111111;
		font-size: 12px;
		font-weight: 400;
	}
	.table .colored > div {
		width: 85px;
		background-color: #EDEDED;
		padding: 4px;
		font-size: 11px;
		font-weight: 700;
		color: #000000;
	}
	.table .colored:last-child .small {
		width: 41px;
	}
	.table tbody .text-danger {
		color: #FF644A;
		font-size: 14px;
		font-weight: 700;
	}
	.fa-star.data {
		color: #C2C2C2;
	}
	.load-more {
		font-size: 12px;
		color: #958C8C;
	}
	.flag {
		background-image: url('../assets/img/flag.png');
		background-size: contain;
		background-repeat: no-repeat;
		width: 16px;
		height: 16px;
		display: block;
		margin: 0 auto;
	}
	.tshirt {
		background-image: url('../assets/img/tshirt.png');
		background-size: contain;
		background-repeat: no-repeat;
		width: 20px;
		height: 20px;
		display: block;
		margin: 0 auto;
	}
	.teams div {
		padding: 4px;
	}
	.score div {
		background-color: #044CBF;
		padding: 2px 4px;
		width: 18px;
		height: 24px;
	}
</style>
  