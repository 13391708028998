import { createRouter, createWebHistory } from 'vue-router';
import ScoreView from '../views/ScoreView.vue';
// import TablesView from '../views/TablesView.vue';
// import CupTablesView from '../views/CupTablesView.vue';
import FixturesView from '../views/FixturesView.vue';
import OddsView from '../views/OddsView.vue';
import { __ } from '../lang/i18n';

const routes = [
	{
		path: '/',
		name: 'Score',
		component: ScoreView,
		meta: { title: () => __(`scores`) },
	},
	// {
	// 	path: '/tables',
	// 	name: 'Tables',
	// 	// component: TablesView,
	// 	component: CupTablesView,
	// },
	{
		path: '/fixtures',
		name: 'Fixtures',
		component: FixturesView,
		meta: { title: () => __(`fixtures`) },
	},
	{
		path: '/odds',
		name: 'Odds',
		component: OddsView,
		meta: { title: () => __(`odds`) },
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	document.title = typeof to.meta.title === 'function' ? to.meta.title() + ' - ' + __(`title`) : __(`title`);
	next();
});

export default router;
