import { createApp } from 'vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import '@fortawesome/fontawesome-free/css/all.min.css';

import './assets/css/style.css';

import router from './router';

import store from './store';

import { setupI18n } from './lang/i18n';

// createApp(App).use(router).mount('#app')
const app = createApp(App);

app.use(store);
app.use(router);

setupI18n(app);

app.mount('#app');
