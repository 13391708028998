import { createStore } from 'vuex';
import livescores from './modules/livescores';
import schedules from './modules/schedules';
import odds from './modules/odds';
import teams from './modules/teams';

const store = createStore({
	modules: {
		livescores,
		schedules,
		odds,
		teams,
	},
});

export default store;