export const translations = {
    en: {
		title: "OLE Sports",

		// menu tabs
        scores: "Score",
        tables: "Tables",
        fixtures: "Fixtures",
        odds: "Odds",
		
		// table tabs
        all: "All ",
        hot_games: "HOT Games",
        leagues: "Leagues", 
		
		// Table Headers
        league: "League",
        time: "Time",
        home: "Home",  
        score: "Score",  
        visiting: "Visiting",  
        asian_handicap: "Asian Handicap",  
        oe: "O/E",  
        one_x: "1X",  
        ht: "H-T",  
        more: "More",  
        handicap: "Handicap", 
        over_under: "Over/Under", 
        one_two: "1X2", 
		draw: "Draw", 
		team: "Team", 
		played: "P", 
		wins: "W", 
		draws: "D", 
		loses: "L", 
		gs: "GS", 
		gd: "GD", 
		pts: "PTS", 
		form: "FORM",

		load_more: "More",

		// Footer
		about: "About SOIKEO thể thao",
		providers: "Providers",
		games: "Games",
		information: "Information",
		license: "License",
		play_responsibly: "Play Responsibly",
		copyright: "Copyright © 2023 SOIKEO ALL RIGHTS RESERVED",
		
		about_content: "Soikeo - High quality entertainment platform that meets quality verification. This website is operated under the PAGCOR license issued by the Philippines Government and is supervised by it. SOIKEO provides a variety of gaming and entertainment products, Attractive Promotions, the most intimate deposit and withdrawal channels, Dedicated Supporting, Professional Service,...",
		
		sport: "Sport",
		e_sport: "E-Sport",
		casino: "Casino",
		slot: "Slot",
		fufish: "Fufish",
		lottery: "Lottery",
		card_game: "Card Game",
		horse_race: "Horse Race",
		
		about_us: "About Us",
		menu_play_responsibly: "Play Responsibly",
		privacy_policy: "Privacy Policy",
		terms_conditions: "Terms & Conditions",
		affiliates: "Affiliates",
		blog: "Blog",
		contact_us: "Contact Us",
    },
    vi: {
		title: "Thể thao OLE",
		
		// menu tabs
        scores: "Tỉ số trực tuyến",
        tables: "Bảng xếp hạng",
        fixtures: "Lịch thi đấu",
        odds: "Tỉ lệ kèo",
		
		// table tabs
        all: "Tất cả",
        hot_games: "Trận HOT",
        leagues: "Giải đấu",  
		
		// Table Headers
        league: "Giải",
        time: "Giờ",
        home: "Nhà",  
        score: "Tỷ số",  
        visiting: "Khách",  
        asian_handicap: "Cược chấp",  
        oe: "T/X",  
        one_x: "1X",  
        ht: "H-T",  
        more: "Số liệu",  
        handicap: "Cược chấp", 
        over_under: "Tài xỉu", 
        one_two: "1X2", 
		draw: "Hoà", 
		team: "ĐỘI", 
		played: "TR", 
		wins: "W", 
		draws: "H", 
		loses: "L", 
		gs: "G", 
		gd: "HSBT", 
		pts: "Đ", 
		form: "PHONG ĐỘ",

		load_more: "Xem thêm",

		// Footer
		about: "Về SOIKEO Thể Thao",
		providers: "Nhà Cung Cấp",
		games: "Trò Chơi",
		information: "Thông Tin",
		license: "Giấy Phép",
		play_responsibly: "Chơi Có Trách Nhiệm",
		copyright: "Copyright © 2023 SOIKEO ALL RIGHTS RESERVED",
		
		about_content: "soikeo - nền tảng giải trí chuyên nghiệp chất lượng cao, đạt chỉ tiêu kiểm định và được cấp phép bởi chính phủ Philippines. Nổi bật với kho trò chơi đa dạng, khuyến mãi hấp dẫn, thanh toán siêu tốc, hỗ trợ thành viên tận tâm, chuyên nghiệp,...",
		
		sport: "thể thao",
		e_sport: "thể thao điện tử",
		casino: "sòng bài",
		slot: "nổ hũ",
		fufish: "bắn cá",
		lottery: "xổ số",
		card_game: "game bài",
		horse_race: "đua ngựa",
		
		about_us: "về chúng tôi",
		menu_play_responsibly: "chơi có trách nhiệm",
		privacy_policy: "bảo vệ quyền riêng tư",
		terms_conditions: "điều khoản quy tắc",
		affiliates: "đại lý",
		blog: "blog",
		contact_us: "liên hệ với chúng tôi",
    }
};