import axiosInstance from '../../axios';

const state = {
	schedules: [],
	schedulesLoaded: false
};

const mutations = {
	setSchedules(state, schedules) {
		state.schedules = schedules;
	},
	setSchedulesLoaded(state, loaded) {
		state.schedulesLoaded = loaded;
	},
};

const actions = {
	async fetchSchedules({ commit, state }) {
		if (state.schedulesLoaded) return;
		try {
			const response = await axiosInstance.get('/sport/football/schedule?leagueId=1763');
			const response2 = await axiosInstance.get(`/sport/football/schedule?leagueId=14228`);

			commit('setSchedules', [...response.data.data, ...response2.data.data]);
			commit('setSchedulesLoaded', true);
		} catch (error) {
			console.error('Error fetching schedules:', error);
		}
	},
	async updateSchedules({ commit }) {
		try {
			const response = await axiosInstance.get('/sport/football/schedule?leagueId=1763');
			const response2 = await axiosInstance.get(`/sport/football/schedule?leagueId=14228`);

			commit('setSchedules', [...response.data.data, ...response2.data.data]);
			commit('setSchedulesLoaded', true);
		} catch (error) {
			console.error('Error fetching schedules:', error);
		}
	}
};

const getters = {
	getSchedules(state) {
		return state.schedules;
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
