<template>
	<div class="score-data">
		<div class="row align-items-center filters">
			<div class="col-md-6">
				<div class="d-flex flex-row justify-content-center justify-content-md-start gap-1 filter-type">
					<a href="#" class="text-center type all active" id="all">{{ $tr('all') }}</a>
					<a href="#" class="text-center type hot-games d-none" id="hot-games">{{ $tr('hot_games') }}</a>
					<a href="#" class="text-center type leagues d-none" id="leagues">{{ $tr('leagues') }}</a>
					<a href="#" class="text-center type odds d-none" id="odds">{{ $tr('odds') }}</a>
				</div>
			</div>
			<div class="col-md-6">
				<div class="d-flex justify-content-end filter-date d-none">
					<div class="d-flex flex-row align-items-center justify-content-between gap-2">
						<i class="fas fa-chevron-left tertiary-color fw-bold cursor-pointer dp__pointer" id="prev" @click="updateSelectedDate('prev')"></i>
						<div class="d-flex flex-row align-items-center gap-2">
							<i class="fas fa-calendar-days solid-color" id="calendar"></i>
							<!-- <span class="primary-color filter-text">13/11 HAI</span> -->
							<DatePicker v-model="selectedDate" :value="selectedDate" @update:model-value="handleDateSelected" />
						</div>
						<i class="fas fa-chevron-right tertiary-color fw-bold cursor-pointer dp__pointer" id="next" @click="updateSelectedDate('next')"></i>
					</div>
				</div>
			</div>
		</div>

		<div class="table-data">
			<div v-if="displayedData && displayedData.length > 0">
				<template v-for="(league, leagueIndex) in displayedData" :key="leagueIndex">
				<!-- <template v-for="match in mergedMatches" :key="match.matchId"> -->
					<table class="table table-borderless mt-3">
						<thead>
							<tr class="header align-middle">
								<th width="2%"><i class="fa-regular fa-star head"></i></th>
								<th width="10%">{{ $tr('league') }}</th>
								<th width="10%">{{ $tr('time') }}</th>
								<th width="20%">{{ $tr('home') }}</th>
								<th width="16%" class="text-center">{{ $tr('score') }}</th>
								<th width="20%">{{ $tr('visiting') }}</th>
								<th width="10%" class="text-center">{{ $tr('asian_handicap') }}</th>
								<th width="10%" class="text-center">{{ $tr('oe') }}</th>
								<th width="2%" class="text-center">{{ $tr('one_x') }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, rowIndex) in league.matches" :key="rowIndex">
								<td><i class="fa-regular fa-star data ps-2"></i></td>
								<td>{{ row.leagueShortName }}</td>
								<td>{{ formatTime(row.matchTime) }}</td>
								<td><span><img v-if="row.homeLogo" :src="storage_api_url + row.homeLogo" :alt="row.homeName" class="logo me-2" /></span>{{ row.homeName }}</td>
								<td class="text-danger text-center">
									<template v-if="notStarted(row.status)">
										-
									</template>
									<template v-else>
										{{ row.homeScore }} - {{ row.awayScore }}
									</template>
								</td>
								<td><span><img v-if="row.awayLogo" :src="storage_api_url + row.awayLogo" :alt="row.awayName" class="logo me-2" /></span>{{ row.awayName  }}</td>
								<td class="colored">
									<div class="d-flex flex-row gap-2 justify-content-end align-items-center mx-auto">
										<template v-if="row.odds.handicap && row.odds.handicap.length > 0">
											<template v-for="(handicap, handicapIndex) in row.odds.handicap" :key="handicapIndex">
												<template v-if="isLive(row.status)">
													<span class="ms-2 live" >{{ handicap.split(',')[2] }}</span><span class="bold">{{ handicap.split(',')[3] }}</span>
													<i :class="getClass(handicap, previousOdds?.handicap?.[handicapIndex], 3)"></i>
												</template>
												<template v-else>
													<span class="ms-2 pre-game" >{{ handicap.split(',')[2] }}</span><span class="bold">{{ handicap.split(',')[3] }}</span>
													<i :class="getClass(handicap, previousOdds?.handicap?.[handicapIndex], 3)"></i>
												</template>
											</template>
										</template>
										<template v-else>
											<span>-</span>
										</template>
									</div>
								</td>
								<td class="colored">
									<div class="d-flex flex-row gap-2 justify-content-end align-items-center mx-auto">
										<template v-if="row.odds.overUnder && row.odds.overUnder.length > 0">
											<template v-for="(overUnder, overUnderIndex) in row.odds.overUnder" :key="overUnderIndex">
												<template v-if="isLive(row.status)">
													<span class="ms-2 live" >{{ overUnder.split(',')[2] }}</span><span class="bold">{{ overUnder.split(',')[4] }}</span>
													<i :class="getClass(overUnder, previousOdds?.overUnder?.[overUnderIndex], 4)"></i>
												</template>
												<template v-else>
													<span class="ms-2 pre-game" >{{ overUnder.split(',')[2] }}</span><span class="bold">{{ overUnder.split(',')[4] }}</span>
													<i :class="getClass(overUnder, previousOdds?.overUnder?.[overUnderIndex], 4)"></i>
												</template>
											</template>
										</template>
										<template v-else>
											<span>-</span>
										</template>
									</div>
								</td>
								<td class="colored d-flex flex-column gap-1">
									<div class="d-flex flex-row gap-2 justify-content-end align-items-center mx-auto small">
										<template v-if="row.odds.europeOdds && row.odds.europeOdds.length > 0">
											<template v-for="(europeOdd, europeOddIndex) in row.odds.europeOdds" :key="europeOddIndex">
												<div class="d-flex justify-content-center align-items-center">
													<template v-if="isLive(row.status)">
														<span class="me-1 live" >{{ europeOdd.split(',')[3] }}</span>
														<i :class="getClass(europeOdd, previousOdds?.europeOdds?.[europeOddIndex], 3)"></i>
													</template>
													<template v-else>
														<span class="me-1 pre-game" >{{ europeOdd.split(',')[3] }}</span>
														<i :class="getClass(europeOdd, previousOdds?.europeOdds?.[europeOddIndex], 3)"></i>
													</template>
												</div>
											</template>
										</template>
										<template v-else>
											<span>-</span>
										</template>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</template>
			</div>
			<div v-else>
				<table class="table table-borderless mt-3">
					<thead>
						<tr class="header align-middle">
							<th width="2%"><i class="fa-regular fa-star head"></i></th>
							<th width="10%">{{ $tr('league') }}</th>
							<th width="10%">{{ $tr('time') }}</th>
							<th width="20%">{{ $tr('home') }}</th>
							<th width="16%" class="text-center">{{ $tr('score') }}</th>
							<th width="20%">{{ $tr('visiting') }}</th>
							<th width="10%" class="text-center">{{ $tr('asian_handicap') }}</th>
							<th width="10%" class="text-center">{{ $tr('oe') }}</th>
							<th width="2%" class="text-center">{{ $tr('one_x') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td colspan="9" class="text-center">--No Available Data--</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<a href="#" v-if="!showAll" @click.prevent="showMore" class="load-more text-center d-block">{{ $tr('load_more') }} <span><i class="fas fa-chevron-down ms-1"></i></span></a>
	</div>
</template>
  
<script>
	import DatePicker from '../components/DatePicker.vue';
	import { mapState, mapActions } from 'vuex';
	export default {
		name: "ScoreView",
		components: {
			DatePicker
		},

		data() {
			return {
				showAll: false,

				mergedMatches: [],
				previousOdds: {},
				teamLogos: {},
				chunkSize: 5,
				startIndex: 0,
				selectedDate: new Date(),
				filteredMatches: [],
				storage_api_url: process.env.VUE_APP_ISPORTS_TEAM_STORAGE_URL
			};
		},
		methods: {
			...mapActions({
				fetchMatches: 'livescores/fetchMatches',
				updateMatches: 'livescores/updateMatches',
				fetchOdds: 'odds/fetchOdds',
				updateOdds: 'odds/updateOdds',
				fetchTeams: 'teams/fetchTeams',
			}),
			handleDateSelected(date) {
				this.selectedDate = date;
				this.filterAndMergeMatches();
			},
			updateSelectedDate(direction) {
				const newDate = new Date(this.selectedDate);

				if(direction == 'next') {
					newDate.setDate(newDate.getDate() + 1);
				} else if (direction == 'prev') {
					newDate.setDate(newDate.getDate() - 1);
				}

				this.selectedDate = newDate;
				this.filterAndMergeMatches();
			},
			async fetchData() {
				await this.fetchMatches();
				await this.fetchOdds();

				this.filterAndMergeMatches();

				
				await this.fetchTeams();
				
				this.mergeData(this.teams);
			},
			async updateData() {
				await this.updateOdds();
				await this.updateMatches();

				// Update only the fields that have changed in matches
				this.updatedMatches.forEach(updatedMatch => {
					const matchIndex = this.matches.findIndex(match => match.matchId === updatedMatch.matchId);
					if (matchIndex !== -1) {
						this.matches[matchIndex] = { ...this.matches[matchIndex], ...updatedMatch };
					}
					// else {
					// 	this.matches.push(updatedMatch);
					// }
				});

				// Update only the fields that have changed in odds
				for (const [key, newOdds] of Object.entries(this.updatedOdds)) {
					if (this.odds[key]) {
						const existingOddsMap = this.odds[key].reduce((acc, odd) => {
							const [matchId] = odd.split(',');
							acc[matchId] = odd;
							return acc;
						}, {});

						newOdds.forEach(odd => {
							const [matchId] = odd.split(',');
							existingOddsMap[matchId] = odd;
						});

						this.odds[key] = Object.values(existingOddsMap);
					} else {
						this.odds[key] = newOdds;
					}
				}

				this.filterAndMergeMatches();
			},
			mergeData(teamLogos) {
				const newGroupedMatches = this.filteredMatches.reduce((acc, match) => {
					const matchOdds = this.findMatchOdds(match.matchId);
					const leagueId = match.leagueId;
					
					if (!acc[leagueId]) {
						acc[leagueId] = {
							leagueName: match.leagueName,
							location: match.location,
							matches: []
						};
					}

					acc[leagueId].matches.push({
						...match,
						homeLogo: teamLogos[match.homeId] || '',
						awayLogo: teamLogos[match.awayId] || '',
						odds: matchOdds,
					});

					return acc;
				}, {});

				this.previousOdds = JSON.parse(JSON.stringify(this.odds));
				this.mergedMatches = newGroupedMatches;
			},
			findMatchOdds(matchId) {
				const odds = {};
				for (const [key, value] of Object.entries(this.odds)) {
					odds[key] = value.filter(odd => odd.split(',')[0] === matchId);
				}
				return odds;
			},
			filterAndMergeMatches() {
				const startDate = new Date(this.selectedDate);
				const endDate = new Date(this.selectedDate);
				endDate.setDate(endDate.getDate() + 7);

				const today = new Date();
				const todayDateString = today.toISOString().split('T')[0];

				this.filteredMatches = this.matches.filter(match => {
					const matchDate = new Date(match.matchTime * 1000);
					const matchDateString = matchDate.toISOString().split('T')[0];
					return (matchDate >= startDate && matchDate <= endDate) || matchDateString === todayDateString;
				});

				this.mergeData(this.teams);
			},
			getClass(current, previous, oddsIndex) {
				const currentValue = parseFloat(current.split(',')[oddsIndex]);
				const previousValue = previous ? parseFloat(previous.split(',')[oddsIndex]) : currentValue;
				if (currentValue > previousValue) {
					return 'fas fa-caret-up text-success';
				} else if (currentValue < previousValue) {
					return 'fas fa-caret-down text-danger';
				} else {
					return 'fas fa-caret-up text-success';
				}
			},
			formatTime(timestamp) {
				let date = new Date(timestamp * 1000);
				let hours = date.getHours().toString().padStart(2, '0');
				let minutes = date.getMinutes().toString().padStart(2, '0');
				return hours + ':' + minutes;
			},
			// formatTime(timestamp) {
			// 	let currentDate = new Date();
			// 	let date = new Date(timestamp * 1000);

			// 	if (date > currentDate) {
			// 		let timeDiffHours = Math.floor((date - currentDate) / (1000 * 60 * 60));

			// 		currentDate.setHours(currentDate.getHours() + timeDiffHours);
			// 	}

			// 	let hours = currentDate.getHours().toString().padStart(2, '0');
			// 	let minutes = currentDate.getMinutes().toString().padStart(2, '0');

			// 	return hours + ':' + minutes;
			// },
			loadMoreData() {
				this.startIndex += this.chunkSize;
			},
			isLive(status) {
				switch (status) {
					case 1:
					case 2:
					case 3:
					case 4:
					case 5:
						return true;
					default:
						return false;
				}
			},
			notStarted(status) {
				switch (status) {
					case 0:
						return true;
					default:
						return false;
				}
			},
			showMore() {
				this.chunkSize += 5;
				
				if (this.chunkSize >= Object.values(this.mergedMatches).length) {
					this.showAll = true;
				}
			},
		},
		created() {
			this.fetchData();
			setInterval(this.updateData, 30000); // 30 seconds
		},
		computed: {
			...mapState({
				matches: state => state.livescores.matches,
				updatedMatches: state => state.livescores.updatedMatches,
				odds: state => state.odds.odds,
				updatedOdds: state => state.odds.updatedOdds,
				teams: state => state.teams.teams,
			}),
			// displayedData() {
			// 	return this.mergedMatches.slice(0, this.startIndex + this.chunkSize);
			// },
			displayedData() {
				const matchesArray = Object.values(this.mergedMatches);

				// show first
				matchesArray.sort((a, b) => {
					const keyA = Object.keys(this.mergedMatches).find(key => this.mergedMatches[key] === a);
					const keyB = Object.keys(this.mergedMatches).find(key => this.mergedMatches[key] === b);
					
					// EURO Cup
					if (keyA === '1763') return -1;
					if (keyB === '1763') return 1;

					// Copa America
					if (keyA === '14228') return -1;
					if (keyB === '14228') return 1;
					return 0;
				});

				return this.showAll ? matchesArray : matchesArray.slice(0, this.chunkSize);
			},

			shouldShowMoreButton() {
				return this.startIndex < this.mergedMatches.length;
			},

			tableChunks() {
				const chunkSize = 5;
				const chunks = [];
				for (let i = 0; i < this.displayedData.length; i += chunkSize) {
					chunks.push(this.displayedData.slice(i, i + chunkSize));
				}
				return chunks;
			}
		}
	}
</script>

<style scoped>
	.filter-type {
		gap: 4px;
	}
	.type {
		padding: 4px 8px;
		border-radius: 8px;
		background-color: #EEEEEE;
		border: 1px solid #EEEEEE;
		font-size: 14px;
		color: #8A8A8A;
		font-weight: 600;
		min-width: 62px;
	}
	.type:hover,
	.type:focus,
	.type.active {
		border: 1px solid #044CBF;
		background-color: #FFFFFF;
		color: #044CBF;
	}
	.filter-text {
		font-size: 14px;
		font-weight: 600;
	}
	.filter-date > div {
		border: 1px solid #CCCCCC;
		border-radius: 8px;
		padding: 4px 16px;
		width: auto;
	}

	.table thead th:first-child, .header th:first-child {
		border-top-left-radius: 10px;
	}
	.table thead th:last-child, .header th:last-child {
		border-top-right-radius: 10px;
	}
	.table thead th, .header th {
		background-color: #044CBF;
		color: #FFFFFF;
		border-bottom: 3px solid #FF644A;
		padding: 8px 16px;
		font-size: 13px;
		font-weight: 700;
	}
	.table td {
		color: #111111;
		font-size: 12px;
		font-weight: 400;
	}
	.table td.colored > div {
		width: 85px;
		background-color: #EDEDED;
		padding: 4px;
		font-size: 11px;
		font-weight: 700;
		color: #000000;
	}
	.table td.colored:last-child div {
		width: 41px;
	}
	.table .colored:last-child .small {
		width: 41px;
	}
	.table tbody .text-danger {
		color: #FF644A;
		font-size: 14px;
		font-weight: 700;
	}
	.fa-star.data {
		color: #C2C2C2;
	}
	.load-more {
		font-size: 12px;
		color: #958C8C;
	}
	.logo {
		width: 16px;
		height: 16px;
	}
</style>
  